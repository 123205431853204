import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

import WebClient from '../utils/web-client';
import { logout, fetchCurrentUser } from '../redux/auth';

const refreshAuth = async () => {};

const Gate = ({ children }) => {
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const refreshFn = async () => {
            try {
                // reauthenticate user if token is still valid
                if (localStorage.getItem('authToken')) {
                    await dispatch(fetchCurrentUser());
                }
            } finally {
                // We proceed once attempt settles, successful or not, as at this point
                // we have enough info to know the user's authentication status, which we need
                // to route the user appropriately (can't render routes and nav guards before settled)
                setIsReady(true);
            }
        };

        const interceptor = WebClient.interceptors.response.use(null, async (error) => {
            if (error.response?.status === 403) {
                dispatch(logout());
            }
            return Promise.reject(error);
        });

        // Try to refresh auth state, if any, opening the gate (rendering routes) regardless of result once our work's done
        refreshFn();

        return () => {
            WebClient.interceptors.response.eject(interceptor);
        };
    }, []);

    if (!isReady) {
        return <Skeleton variant="rectangular" width="100%" animation="wave" height="100vh" />;
    }

    return children;
};

Gate.propTypes = {
    children: PropTypes.node.isRequired,
};

export { Gate, refreshAuth };
