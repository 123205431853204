import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
// TODO: any issue with this being loaded in prod? it's a dev only need
import axe from '@axe-core/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { Provider } from 'react-redux';

import posthog from 'posthog-js';
import theme from './styles/material-theme';
import ErrorFallback from './components/error-fallback';

import App from './routes';

import 'sanitize.css/sanitize.css';
import './index.css';
import ScrollToTop from './components/scroll-to-top';
import GoogleAnalytics from './components/google-analytics';
import { init as sentryInit } from './utils/sentry';
import ProcessingFeedback from './components/processing-feedback';
import QueueProvider from './wiring/queue';
import store from './redux';

if (!import.meta.env.PROD) {
    axe(React, ReactDOM, 1000);
}

const container = document.getElementById('root');
const root = createRoot(container);
posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_HOST,
    person_profiles: 'identified_only',
});
// initialize sentry
sentryInit();

root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
                    <QueueProvider>
                        <ScrollToTop />
                        <GoogleAnalytics />
                        <App store={store} />
                        <ProcessingFeedback />
                    </QueueProvider>
                </Sentry.ErrorBoundary>
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
);
