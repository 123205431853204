import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import Colors from '../../styles/colors';
import Snackbar from '../snackbar';

import { getBackgroundProcessing, getUploadsInflight, getSnackbarConfig, closeSnackbar } from '../../redux/processing';

const beforeUnloadHandler = (ev) => {
    ev.preventDefault();
};

const ProcessingFeedback = ({ children }) => {
    const dispatch = useDispatch();
    const isBackgroundProcessing = useSelector(getBackgroundProcessing);
    const uploadsInflight = useSelector(getUploadsInflight);
    const snackbarConfig = useSelector(getSnackbarConfig);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isBackgroundProcessing) {
            window.addEventListener('beforeunload', beforeUnloadHandler);

            return () => {
                window.removeEventListener('beforeunload', beforeUnloadHandler);
            };
        }
    }, [isBackgroundProcessing]);

    const { message, severity } = snackbarConfig;

    return (
        <>
            {children}
            {uploadsInflight && (
                <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, backgroundColor: 'processing.main' }}>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        color={Colors.black}
                        sx={{ textAlign: 'center', py: 1 }}
                    >
                        File upload in progress
                    </Typography>
                </AppBar>
            )}
            <Snackbar
                open={!!message}
                message={message}
                severity={severity}
                onClose={() => dispatch(closeSnackbar())}
            />
        </>
    );
};

ProcessingFeedback.propTypes = {
    children: PropTypes.node,
};

ProcessingFeedback.defaultProps = {
    children: null,
};

export default ProcessingFeedback;
