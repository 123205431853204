import PropTypes from 'prop-types';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

const Snackbar = ({ open, onClose, message, severity, autoHideDisabled, sx, backgroundColor, ...props }) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose();
    };
    return (
        <MuiSnackbar
            open={open}
            autoHideDuration={autoHideDisabled ? null : 5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            // zIndex hackery to ensure this overlays most in-flow elements
            sx={{ width: '100%', zIndex: 999, ...sx }}
            {...props}
        >
            {/* A bit janky, but hiding if !open to prevent a split second reversion to our default severity
            Due to how our connected snackbar works, whereby we flip open to false when the text is cleared from
            redux; at the same, we empty severity, so the snackbar is racing to close while the alert is rerendering
             */}
            {open ? (
                <Alert
                    severity={severity ?? 'success'} // handles null, which triggers a crash
                    variant="filled"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            disableFocusRipple
                            disableRipple
                            onClick={onClose}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        textAlign: 'center',
                        minWidth: '300px',
                        ...(['error', 'warning'].includes(severity) && {
                            color: 'white',
                            '.MuiAlert-icon': { color: 'white' },
                        }),
                    }}
                >
                    <Typography variant="body1">{message}</Typography>
                </Alert>
            ) : (
                <div />
            )}
        </MuiSnackbar>
    );
};

Snackbar.defaultProps = {
    message: '',
    severity: 'success',
    autoHideDisabled: null,
    sx: {},
    backgroundColor: '',
};

Snackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.node,
    severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
    autoHideDisabled: PropTypes.bool,
    sx: PropTypes.object,
    backgroundColor: PropTypes.string,
};

export default Snackbar;
