import { createTheme } from '@mui/material/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const baseTheme = createTheme({
    typography: {
        fontFamily: '"Archivo", sans-serif',
        fontWeight: 400,
        h1: {
            fontSize: '3.2rem',
            fontWeight: 'bold',
            lineHeight: '120%',
        },
        h2: {
            fontSize: '2.4rem',
            fontWeight: 'bold',
            lineHeight: '120%',
        },
        h3: {
            fontSize: '2.0rem',
            fontWeight: 600,
            lineHeight: '120%',
        },
        h4: {
            fontSize: '1.6rem',
            fontWeight: 600,
            lineHeight: '120%',
        },
        h5: {
            fontSize: '1.4rem',
            fontWeight: 600,
            lineHeight: '120%',
        },
        h6: {
            fontSize: '1.2rem',
            fontWeight: 600,
            lineHeight: '120%',
        },
        body1: {
            fontSize: '1.3rem',
            lineHeight: '1.52rem',
        },
        body2: {
            fontSize: '1.5rem',
            color: Colors.greyDark,
        },
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        text: {
            primary: Colors.black,
            secondary: Colors.greyDark,
        },
        primary: {
            main: Colors.primaryMain,
            light: Colors.primaryLight,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.secondaryMain,
            contrastText: Colors.white,
        },
        tertiary: {
            main: Colors.brightPink,
            contrastText: Colors.black,
        },
        quaternary: {
            main: Colors.tAndPink,
        },
        background: {
            default: Colors.white,
            paper: Colors.greyLightest,
        },
        error: {
            main: Colors.errorRed,
            light: Colors.error,
        },
        success: {
            main: Colors.success,
        },
        processing: {
            main: Colors.blue,
        },
        link: {
            main: Colors.linkText,
            light: Colors.linkLight,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        body2: {
            color: baseTheme.palette.text.secondary,
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: {
            // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: 'inherit',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: 0,
                    left: 0,
                    color: baseTheme.palette.text.primary,
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    '&.Mui-error, &.Mui-focused': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                    '&.Mui-disabled': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.3rem',
                    marginLeft: 0,
                    marginRight: 0,
                    color: baseTheme.palette.text.secondary,
                    '&.Mui-error': {
                        color: baseTheme.palette.text.error,
                        fontWeight: '600',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.primary,
                    '&.Mui-disabled': {
                        backgroundColor: Colors.greyLight,
                        borderColor: Colors.greyMedium,
                        color: Colors.black,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1.5rem',
                    marginTop: 5,
                    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: Colors.blue,
                    },
                    // Textarea height should adapt to content / Mui's rows prop
                    ':not(.MuiInputBase-multiline)': {
                        height: 40,
                    },
                },
                notchedOutline: {
                    borderColor: Colors.greyDark,
                    color: Colors.greyDark,
                    padding: '10px 12px',
                    '&.Mui-disabled': {
                        backgroundColor: Colors.greyLight,
                        borderColor: Colors.greyMedium,
                        color: Colors.black,
                        webkitTextFillColor: Colors.black,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: Colors.blue,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: Colors.blue,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.secondary,
                    lineHeight: 1.1875,
                    fontSize: '1.5rem',

                    '&[aria-selected=true]': {
                        color: baseTheme.palette.primary.contrastText,
                        backgroundColor: baseTheme.palette.primary.main,
                    },

                    '&[role=option]': {
                        // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 10px 19px -4px rgba(0, 0, 0, 0.15)',
                    borderRadius: '0 0 8px 8px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    lineHeight: '2.1rem',
                    textTransform: 'none',
                    fontSize: '1.6rem',
                    padding: `${baseTheme.spacing(1.5)} ${baseTheme.spacing(2)}`,
                    '&:focus-visible': {
                        boxShadow: '0px 0px 5px rgba(0, 128, 255, 0.9)',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.primary.light,
                    },
                    '&.Mui-disabled': {
                        backgroundColor: Colors.grey,
                        borderColor: Colors.grey,
                        color: Colors.greyMediumDark,
                    },
                },
                outlinedPrimary: {
                    backgroundColor: 'white',
                    borderColor: 'primary.main',
                    border: '2px solid',
                    '&:hover': {
                        border: '2px solid', // override default hover effect from MUI
                        backgroundColor: Colors.primaryHighlight,
                    },
                    padding: `calc(${baseTheme.spacing(1.5)} - 2px) ${baseTheme.spacing(2)}`,
                    '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                        border: '1.5px solid',
                        borderColor: Colors.greyMedium,
                        color: Colors.whiteDark,
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.secondary.light,
                    },
                },
                containedTertiary: {
                    '&:hover': {
                        backgroundColor: baseTheme.palette.tertiary.light,
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                primary: {
                    '&.Mui-disabled, &[aria-disabled=true]': {
                        opacity: '40%',
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1.5rem',
                    '&.Mui-selected': {
                        backgroundColor: baseTheme.palette.primary.main,
                        color: baseTheme.palette.primary.contrastText,
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontSize: '1.5rem',
                },
                root: {
                    color: baseTheme.palette.text.primary,
                    '& .MuiAlert-icon': {
                        color: baseTheme.palette.text.primary,
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '& .MuiAlert-root': {
                        alignItems: 'center',

                        '& .MuiAlert-action': {
                            paddingTop: 0,
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: Colors.black,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1.2rem',
                },
            },
        },
    },
});

export default theme;
