/* eslint-disable camelcase */
import WebClient from '../utils/web-client';

const sleep = (ms) =>
    new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

const request = async (netFn, opts = { log: false, latency: false, cancelToken: null }) => {
    try {
        if (opts.latency) {
            await sleep(Number.isNaN(opts.latency) ? 2000 : opts.latency);
        }
        const result = await netFn({ cancelToken: opts.cancelToken });

        if (opts.log) {
            // eslint-disable-next-line no-console
            console.log(result);
        }

        return result;
    } catch (err) {
        if (opts.log) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
        throw err;
    }
};

const API = {
    checkToken: async (opts) => {
        await request(({ cancelToken }) => WebClient.get('/auth/refresh-token', { cancelToken }), opts);
    },
    fetchCurrentUser: async (opts) => {
        const { data } = await request(({ cancelToken }) => WebClient.get('/users/me', { cancelToken }), opts);

        return data;
    },
    updateCurrentUser: async ({ full_name }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.patch(
                    '/users/me',
                    {
                        full_name,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    changePassword: async ({ password }, opts) => {
        await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/users/me/reset_password',
                    {
                        password,
                    },
                    { cancelToken },
                ),
            opts,
        );
    },
    login: async ({ username, password }, opts) => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);

        const { data } = await request(
            ({ cancelToken }) => WebClient.post('/auth/access-token', formData, { cancelToken }),
            opts,
        );

        return data;
    },
    requestPasswordReset: async ({ email }, opts) => {
        await request(
            ({ cancelToken }) => WebClient.post('/auth/request_password_reset', { username: email }, { cancelToken }),
            opts,
        );
    },
    resetForgottenPassword: async ({ new_password, reset_token }, opts) => {
        await request(
            ({ cancelToken }) =>
                WebClient.post('/auth/forgot_password_reset', { new_password, reset_token }, { cancelToken }),
            opts,
        );
    },
    register: async ({ email, password, full_name }, opts) => {
        await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/users/register',
                    {
                        email,
                        password,
                        full_name,
                    },
                    { cancelToken },
                ),
            opts,
        );
    },
    createFileIngestJob: async ({ case_id, filename_id }, opts) => {
        const {
            data: { job_id },
        } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/ingest',
                    {
                        case_id,
                        filename_id,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return { jobId: job_id };
    },
    getJobStatus: async ({ file_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get(`/files/${file_id}/status`, { cancelToken }),
            opts,
        );

        return data;
    },
    updateFile: async ({ file_id, ingest_status, ingest_completed_at }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.put(`/files/${file_id}`, { ingest_status, ingest_completed_at }, { cancelToken }),
            opts,
        );

        return data;
    },
    generateCaseToc: async ({ case_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post(`/cases/${case_id}/toc`, {}, { cancelToken }),
            opts,
        );

        return data;
    },
    createFile: async ({ case_id, name, job_id, filename_id }, opts) => {
        const { data: file } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/files/', // trailing slash needed; server configured to redirect files to files/ (which is fine, but figure better to hit the endpoint directly)
                    {
                        name,
                        job_id,
                        case_id,
                        filename_id,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return file;
    },
    S3getPresignedDownloadUrl: async ({ file_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.get(
                    `/files/${file_id}/download_url`,
                    {
                        file_id,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data.presigned_url;
    },
    S3getPresignedUploadUrl: async ({ filename_id, case_id, content_type }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/get_presigned_upload_url',
                    {
                        filename_id,
                        case_id,
                        content_type,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data.presigned_url;
    },
    S3getMultipartUploadPresignedUrls: async ({ filename_id, case_id, parts, upload_id }, opts) => {
        const {
            data: { presigned_urls: urls },
        } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/get_multipart_upload_part_url',
                    {
                        filename_id,
                        case_id,
                        parts,
                        upload_id,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return urls;
    },
    S3getMultipartUploadId: async ({ filename_id, case_id, content_type }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/get_multipart_upload_id',
                    {
                        filename_id,
                        case_id,
                        content_type,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    S3completeMultipartUpload: async ({ filename_id, case_id, upload_id, parts }, opts) => {
        await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/get_multipart_upload_complete_url',
                    {
                        filename_id,
                        case_id,
                        upload_id,
                        parts, // [{ ETag, PartNumber }]
                    },
                    { cancelToken },
                ),
            opts,
        );
    },
    S3abortMultipartUpload: async ({ filename_id, case_id, upload_id }) => {
        await request(({ cancelToken }) =>
            WebClient.post(
                '/api/abort_multipart_upload',
                {
                    filename_id,
                    case_id,
                    upload_id,
                },
                { cancelToken },
            ),
        );
    },
    getAIQueries: async ({ case_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.get(`/cases/${case_id}/queries`, { cancelToken }),
            opts,
        );

        return data;
    },
    queryAI: async ({ query, case_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/query',
                    {
                        case_id,
                        query,
                    },
                    { cancelToken },
                ),
            opts,
        );

        return data;
    },
    generateAIResponse: async ({ context, query, conversation, documents, case_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) =>
                WebClient.post(
                    '/api/generate',
                    {
                        context,
                        query,
                        conversation,
                        documents,
                        case_id,
                    },
                    { cancelToken },
                ),
            opts,
        );
        return data;
    },
    getDocument: async ({ document_id }, opts) => {
        const { data } = await request(
            ({ cancelToken }) => WebClient.post('/api/get_document', { document_id }, { cancelToken }),
            opts,
        );
        return data;
    },
};

export default API;
