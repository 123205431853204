import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import authReducer from './auth';
import processingReducer from './processing';
import thunkCrashReporter from './thunk-crash-reporter';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
    devTools: import.meta.env.VITE_DEPLOY_ENV !== 'production',
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunkCrashReporter),
    reducer: {
        auth: authReducer,
        processing: processingReducer,
    },
});

export default store;
