// This rule is basically in direct conflict w/ Immer
/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import API from '../../api';

const fetchCurrentUser = createAsyncThunk('auth/fetchCurrentUser', async (reqOpts, thunkApi) => {
    const source = Axios.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
        source.cancel();
    });

    const data = await API.fetchCurrentUser(reqOpts);
    return data;
});

const updateCurrentUser = createAsyncThunk('auth/updateCurrentUser', async ({ data, reqOpts }) =>
    API.updateCurrentUser(data, reqOpts),
);

const login = createAsyncThunk('auth/login', async ({ formValues, onSuccess, onError, reqOpts }, thunkApi) => {
    const { email, password } = formValues;
    try {
        const data = await API.login({ username: email, password }, reqOpts);

        localStorage.setItem('authToken', data.access_token);

        await thunkApi.dispatch(fetchCurrentUser());

        onSuccess?.();

        return data.access_token;
    } catch (error) {
        onError?.(error);

        throw error;
    }
});

const initialState = {
    currentUser: null,
    authToken: null,
    loading: false,
    inflightRequests: {
        login: null,
        fetchCurrentUser: null,
        updateCurrentUser: null,
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout() {
            localStorage.removeItem('authToken');
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            // login
            .addCase(login.pending, (state, action) => {
                if (!state.loading) {
                    state.loading = true;
                }

                state.inflightRequests.login = action.meta.requestId;
            })
            .addCase(login.fulfilled, (state, action) => {
                if (state.loading && state.inflightRequests.login === action.meta.requestId) {
                    state.loading = false;
                    state.authToken = action.payload;
                    state.inflightRequests.login = null;
                }
            })
            .addCase(login.rejected, (state, action) => {
                if (state.loading && state.inflightRequests.login === action.meta.requestId) {
                    state.loading = false;
                    state.inflightRequests.login = null;
                }
            })
            // Fetch current user
            .addCase(fetchCurrentUser.pending, (state, action) => {
                if (!state.loading) {
                    state.loading = true;
                }

                state.inflightRequests.fetchCurrentUser = action.meta.requestId;
            })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
                if (state.loading && state.inflightRequests.fetchCurrentUser === action.meta.requestId) {
                    state.loading = false;
                    state.currentUser = action.payload;
                    state.inflightRequests.fetchCurrentUser = null;
                }
            })
            .addCase(fetchCurrentUser.rejected, (state, action) => {
                if (state.loading && state.inflightRequests.fetchCurrentUser === action.meta.requestId) {
                    state.loading = false;
                    state.inflightRequests.fetchCurrentUser = null;
                }
            })
            // Update current user
            .addCase(updateCurrentUser.pending, (state, action) => {
                state.inflightRequests.updateCurrentUser = action.meta.requestId;
            })
            .addCase(updateCurrentUser.fulfilled, (state, action) => {
                if (state.inflightRequests.updateCurrentUser === action.meta.requestId) {
                    state.currentUser = action.payload;
                }
            })
            .addMatcher(updateCurrentUser.settled, (state, action) => {
                if (state.inflightRequests.updateCurrentUser === action.meta.requestId) {
                    state.inflightRequests.updateCurrentUser = null;
                }
            });
    },
});

// Extract the action creators object and the reducer
const { reducer, actions } = authSlice;

export { fetchCurrentUser, updateCurrentUser, login };
export const { logout } = actions;

// Export the reducer, either as a default or named export
export default reducer;
