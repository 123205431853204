// This rule is basically in direct conflict w/ Immer
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import API from '../api';
import WebClient from '../utils/web-client';

/*

Helpful references:

- https://docs.aws.amazon.com/AmazonS3/latest/userguide/mpuoverview.html
- https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
- Good reference example: https://github.com/awsdocs/aws-doc-sdk-examples/blob/main/javascriptv3/example_code/s3/scenarios/multipart-upload.js
- https://github.com/aws-samples/amazon-s3-multipart-upload-transfer-acceleration/blob/main/backendV2/lambda/makePreSignedUrls.js
- https://archive.org/details/texts good source for test data
- Discussion of how to think about data transfer performance variables: https://stackoverflow.com/a/46564791

*/

// File size above which we will use multipart uploads
// https://docs.aws.amazon.com/AmazonS3/latest/userguide/mpuoverview.html
// AWS recommends above 100MB, but _very_ basic testing showed that parallelization
// sped up uploads even for smaller files. Of course this is extremely anecdotal and will vary depending on all sorts of factors like
// the user's internet connection, could tune upward as needed
const MPU_THRESHOLD = 5 * 1024 * 1024; // 5 MiB, AWS' lower limit for multipart uploads: https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html

// Size of each part in a multipart upload
// tradeoffs: more parts = more parallelism, but more overhead?? (network reqs to get data needed)
// 10,000 parts is the max; 5MiB is the min part size; https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
// Multipart uploads require a minimum size of 5 MiB per part.
// Increasing part size probably won't have too much impact on overall performance, but would decrease the number of connections spawned to
// AWS, such that we're more likely to stay below the browser's connections per server limit (6 connections per host max), thereby avoiding connection queueing.
// Anecdotally, there does seem to be some overhead involved in creating a new connection and said queueing, but unclear how that trades off with
// the extra time spent per connection given increased payload size. In either case, unless the user's upload bandwidth is very high, the bottleneck is likely to be the user's connection speed, not the number of connections
const PART_SIZE = 5 * 1024 * 1024;

const handleSinglePartUpload = async (file, caseId, onCompleteFile) => {
    const key = file.id;

    const presignedUrl = await API.S3getPresignedUploadUrl({
        filename_id: key,
        case_id: caseId,
        content_type: file.type,
    });
    await WebClient.put(presignedUrl, file, {
        headers: {
            // browser will auto-detect file type and set identically, listing
            // here just to be explicit
            'Content-Type': file.type,
        },
        skipAuthInterceptor: true, // AWS errors if Bearer auth provided on top of auth encoded in presigned URL query params
    });

    onCompleteFile(file);
};

const handleMultipartUpload = async (file, caseId, onCompleteFile) => {
    // See https://docs.aws.amazon.com/AmazonS3/latest/userguide/mpuoverview.html
    // for details on multipart uploading process

    const key = file.id;

    const numberOfparts = Math.ceil(file.size / PART_SIZE);

    const { uploadId } = await API.S3getMultipartUploadId({
        filename_id: key,
        case_id: caseId,
        content_type: file.type,
    });

    try {
        // TODO What happens if we hit our browser's connection limit? Does it queue up the requests?
        const presignedUrls = await API.S3getMultipartUploadPresignedUrls({
            filename_id: key,
            case_id: caseId,
            parts: numberOfparts,
            upload_id: uploadId,
        });

        // TODO deal with queueing / connection limits after testing

        const errorListeners = [];
        let uploaded;
        try {
            uploaded = await Promise.all(
                presignedUrls.map(async (presignedUrl, i) => {
                    // TODO Link example implementation as helpful reference
                    const sentSize = i * PART_SIZE;
                    const chunk = file.slice(sentSize, sentSize + PART_SIZE);

                    const controller = new AbortController();

                    // we want to bail as early as possible if we know the upload's failed, hence Promise.all,
                    // but assume that some part uploads could still be in progress, so we cancel them instead
                    // to proactively free up connections instead of letting them run (this has the added side-benefit
                    // of preventing parts from being uploaded for a failed upload, but we use the call to abortMultipartUpload
                    // below, as well as a lifecycle configuration, to clean abandoned parts up on the S3 side)
                    errorListeners.push(() => {
                        controller.abort();
                    });

                    const { headers } = await WebClient.put(presignedUrl, chunk, {
                        headers: {
                            // https://github.com/boto/boto3/issues/3708#issuecomment-2102403500
                            // Content-Type set here triggers a 403, signature mismatch error from AWS
                            // need to set to false to override Axios' default content-type setting
                            // browser does not autodetect and set a content-type here, I imagine
                            // because chunk is a part of a file, not a file itself, i.e. essentially arbitrary binary data
                            'Content-Type': false,
                        },
                        signal: controller.signal,
                        skipAuthInterceptor: true, // AWS errors if Bearer auth provided on top of auth encoded in presigned URL query params
                    });

                    return { PartNumber: i + 1, ETag: headers.etag };
                }),
            );
        } catch (err) {
            errorListeners.forEach((l) => l());
            throw new Error('Failed to upload all parts of the file.');
        }

        await API.S3completeMultipartUpload({
            filename_id: key,
            case_id: caseId,
            upload_id: uploadId,
            parts: uploaded,
        });
    } catch (err) {
        await API.S3abortMultipartUpload({ filename_id: key, case_id: caseId, upload_id: uploadId });
        throw err;
    }

    onCompleteFile(file);
};

const uploadFiles = createAsyncThunk('processing/uploadFiles', async ({ files, caseId, onCompleteFile }, thunkApi) => {
    const results = await Promise.allSettled(
        files.map(async (file) => {
            if (file.size > MPU_THRESHOLD) {
                await handleMultipartUpload(file, caseId, onCompleteFile);
            } else {
                await handleSinglePartUpload(file, caseId, onCompleteFile);
            }
        }),
    );

    if (results.every((r) => r.status === 'fulfilled')) {
        // eslint-disable-next-line no-use-before-define
        thunkApi.dispatch(showSnackbar({ message: 'File upload complete', severity: 'success' }));
    }

    if (results.every((r) => r.status === 'rejected')) {
        // eslint-disable-next-line no-use-before-define
        thunkApi.dispatch(showSnackbar({ message: 'File upload failed', severity: 'error' }));
    } else if (results.some((r) => r.status === 'rejected')) {
        const failed = [];
        results.forEach((r, i) => {
            if (r.status === 'rejected') {
                failed.push(files[i].name);
            }
        });

        thunkApi.dispatch(
            // eslint-disable-next-line no-use-before-define
            showSnackbar({
                message: `Partial success. Some files failed to upload: ${failed.join(', ')}`,
                severity: 'warning',
            }),
        );
    }
});

const generateCaseToc = createAsyncThunk('processing/generateCaseToc', async ({ caseId }, thunkApi) => {
    const state = thunkApi.getState().processing;

    // Crude measure to prevent multiple requests for the same case
    // Puts the onus on the caller to ensure they re-call once this blocker is cleared vs.
    // some sort of queueing system that tracks the call attempt and initiates it automatically
    // on clearing. Probably fine for our limited need here
    //
    // > 1, not 0, b/c calling this thunk dispatches the pending action
    // before reaching here, meaning:
    // - if the current request is the first, the count will be 1
    // - if there's an in-flight request, the current request will be the second
    if (state.inflightRequests.generateCaseToc[caseId] > 1) {
        return thunkApi.rejectWithValue({
            message: 'Request already in progress',
            code: `CASE_TOC_${caseId}_IN_PROGRESS`,
        });
    }

    const data = await API.generateCaseToc({ case_id: caseId });

    return data;
});

const initialState = {
    inflightRequests: { uploadFiles: 0, generateCaseToc: {} },
    snackbarConfig: { message: '' },
};

const processingSlice = createSlice({
    name: 'processing',
    initialState,
    reducers: {
        showSnackbar(state, action) {
            state.snackbarConfig.message = action.payload.message;
            state.snackbarConfig.severity = action.payload.severity;
        },
        // could be expressed with showSnackbar, purely for explicitness / readability in context
        closeSnackbar(state) {
            state.snackbarConfig.message = '';
            state.snackbarConfig.severity = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFiles.pending, (state) => {
                state.inflightRequests.uploadFiles += 1;
            })
            .addCase(generateCaseToc.pending, (state, action) => {
                const { caseId } = action.meta.arg;
                state.inflightRequests.generateCaseToc[caseId] ??= 0;
                state.inflightRequests.generateCaseToc[caseId] += 1;
            })
            .addMatcher(uploadFiles.settled, (state) => {
                if (state.inflightRequests.uploadFiles > 0) {
                    state.inflightRequests.uploadFiles -= 1;
                }
            })
            .addMatcher(generateCaseToc.settled, (state, action) => {
                const { caseId } = action.meta.arg;
                if (state.inflightRequests.generateCaseToc[caseId] > 0) {
                    state.inflightRequests.generateCaseToc[caseId] -= 1;
                }
            });
    },
    selectors: {
        getUploadsInflight: (processingState) => processingState.inflightRequests.uploadFiles > 0,
        getSnackbarConfig: (processingState) => processingState.snackbarConfig,
        getIsCaseTocRequestInflight: (processingState, caseId) =>
            processingState.inflightRequests.generateCaseToc[caseId] > 0,
        getBackgroundProcessing: (processingState) =>
            processingState.inflightRequests.uploadFiles > 0 ||
            Object.values(processingState.inflightRequests.generateCaseToc).some((v) => v > 0),
    },
});

const { showSnackbar, closeSnackbar } = processingSlice.actions;

export { uploadFiles, showSnackbar, closeSnackbar, generateCaseToc };
export const { getUploadsInflight, getSnackbarConfig, getIsCaseTocRequestInflight, getBackgroundProcessing } =
    processingSlice.selectors;

export default processingSlice.reducer;
