import PropTypes from 'prop-types';
import React, { useContext } from 'react';
// p-queue is an ESM package; the module is in fact installed, just not resolvable with
// the linter's rule's module resolution strategy (require.resolve)
// eslint-disable-next-line import/no-unresolved
import PQueue from 'p-queue';

const QueueContext = React.createContext();

const useQueue = () => {
    const queueContext = useContext(QueueContext);

    if (!queueContext) {
        throw new Error('useQueue must be used within a QueueProvider');
    }

    return queueContext;
};

// Assume browser's http connection limit is 6; allow running work concurrently
// while still allowing some room for other, unrestricted requests to go through
const queue = new PQueue({ concurrency: 4 });

const ctx = {
    add: (fn) => queue.add(fn),
};

const QueueProvider = ({ children }) => <QueueContext.Provider value={ctx}>{children}</QueueContext.Provider>;

QueueProvider.propTypes = {
    children: PropTypes.node,
};

QueueProvider.defaultProps = {
    children: null,
};

export { useQueue };

export default QueueProvider;
