export default {
    white: '#ffffff',
    whiteDark: '#F4F4F5',
    black: '#242626',
    grey: '#DFE2E5', // Duplicate of greyMedium; prefer greyMedium, as naming aligns with style guide
    greyDark: '#4B5966',
    greyDarker: '#0F2032',
    greyMedium: '#DFE2E5',
    greyMediumDark: '#ABABAB',
    greyLight: '#F0F2F2',
    greyLightest: '#F6F6F6',
    greyBarely: '#FBFBFB',
    regalBlue: '#1D2E4B',
    tAndPink: '#E60073',
    brightPink: '#FF0080',
    midnight: '#16324D',
    midnightLight: '#162F4A',
    error: '#FFBBB1',
    errorRed: '#ED2626',
    blue: '#B5E4FF',
    clinicalBlue: '#D8E6FC',
    success: '#CAFFB7',
    primaryLight: '#0C8179',
    primaryMain: '#07625B',
    primaryDark: '#4B5966',
    primaryHighlight: '#DDF0F1',
    secondaryHighlight: '#EEF8F8',
    secondaryMain: '#204A78',
    logoGreen: '#6FC8B1',
    linkText: '#1F50FF',
    linkLight: '#DDE2FF',
    legalPurple: '#ECE6FB',
};
